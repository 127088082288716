export const is3WSD18 = false;
export const isFLN = false;
export const isSSM508 = false;
export const isFOOKLEEUNION = false;
export const isHY202318 = true;

export const COOKIESPREFIX = 'CONSTRUCTIONDASHBOARD';

export const DEFAULT_PROJECT_ID =  
    isFLN?'FLN'
    :is3WSD18?"3WSD18"
    :isSSM508?'SSM508'
    :isFOOKLEEUNION?'Fook Lee Construction Co., Ltd. Union Contractors Ltd.'
    :isHY202318?'HY/2023/18'
    :'Project 1';

export const isHideDangerZone=(is3WSD18||isHY202318||isFOOKLEEUNION)?true:false
export const isHideConfinedSpace=(is3WSD18||isHY202318||isFOOKLEEUNION)?true:false
export const isHideSmartLock = (is3WSD18||isHY202318)?true:false
export const isHideSmartWatch = false
export const isHideSmartCamera= is3WSD18?true:false
export const isHidePermit= is3WSD18?true:false
export const isHideTracking= is3WSD18?true:false
export const isHideVR = (is3WSD18||isHY202318)?true:false

export const CPM_DEFAULT_FILTER= isFOOKLEEUNION? '7 Days' :"Today"

export const REFRESH_INTERVAL = 1000*30 //30 seconds 

export const server_ip =''//'http://18.140.58.38'
//demo:'http://18.140.58.38:3000'
//3WSD:'http://18.143.73.17'
//FLN: 'http://13.212.187.22'
//SSM508: 'http://3.1.sudo scp -r -i ~/Downloads/aquila-tech-aws-key.pem ./build/* ubuntu@3.1.206.102:~/construction-dashboard-server/static/206.102'
//FOOKLEEUNION: 'http://18.143.149.108'
//HY/2023/18: 'http://13.215.203.71'

export const GOOGLEMAPAPIKEY = 'AIzaSyBP6_3_nGzAaL1ti6OmphwQvqFI5VhYguc';